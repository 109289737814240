<template>
  <div class="container-fluid">
    <h3 class="text-gray-800">Informações dos Push Batches</h3>

    <div v-if="loading === true" class="d-flex justify-content-center">
        <Loader></Loader>
    </div>

    <div v-else class="card shadow mb-4 mt-4">
      <div class="card-header py-3">
        <div class="d-flex align-items-center justify-content-end">
          <label class="list-clients" for="selectFilterClient"
            ><b>SELECIONE UM CLIENTE: </b></label
          >
          <select
            class="custom-select col-2 btn-header"
            id="selectFilterClient"
            @change="onChangeFilterClient"
          >
            <option value="" selected>-- CLIENTES --</option>
            <option
              v-for="client in clients"
              :value="client.id"
              :key="client.id"
            >
              {{ client.name }}
            </option>
          </select>

          <select
            class="custom-select col-2 btn-header"
            id="selectFilterProject"
            @change="onChangeFilterProject"
          >
            <option value="" selected>TODOS OS PROJETOS</option>
            <option v-for="project in projects" :value="project" :key="project">
              {{ project }}
            </option>
          </select>

          <input type="text" v-model="search_filter" class="form-control col-2" placeholder="Filtrar push batches pelo nome">
          <button type="button" class="btn btn-info ml-2" title="Filtrar" @click="searchPushBatchByName">Filtrar</button>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
            selectedClass="table-info"
          >
            <thead>
              <tr>
                <th class="align-middle">Nome</th>
                <th class="align-middle">Sender</th>
                <th class="align-middle text-center">Status</th>
                <th class="align-middle">Criado por</th>
                <th class="align-middle text-center">Projeto</th>
                <th class="align-middle text-center">Total de Números</th>
                <th class="align-middle text-center">Linhas Processadas</th>
                <th class="align-middle text-center">Falhas</th>
                <th class="align-middle">Modified</th>
                <th class="align-middle text-center">Arquivo</th>
                <th class="align-middle text-center">Visualizar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading_pagination === false && allPushBatches" v-for="data in allPushBatches" v-bind:key="data.id">
                <td class="align-middle">{{ data.identifier }}</td>
                <td class="align-middle">{{ data.sender_phone_number }}</td>
                <td class="align-middle text-center">{{ data.status }}</td>
                <td class="align-middle">{{ data.created_by_email }}</td>
                <td class="align-middle text-center">{{ data.project_name }}</td>
                <td class="align-middle text-center">{{ data.count_lines }}</td>
                <td class="align-middle text-center">{{ data.processed }}</td>
                <td class="align-middle text-center">{{ data.failed }}</td>
                <td class="align-middle">{{ formatDate(data.modified) }}</td>
                <td class="align-middle text-center">
                  <div class="fs-4 mb-3">
                    <i
                      v-if="data.file_url != null"
                      class="bi bi-filetype-csv text-primary button-action"
                      @click="downloadFile(data.file_url)"
                    ></i>
                    <i v-else class="bi bi-x-circle-fill text-danger"></i>
                  </div>
                </td>
                <td class="align-middle text-center">
                  <div class="fs-4 mb-3">
                    <i
                      class="bi bi-eye text-primary button-action"
                      @click="goToPage(data.id)"
                    ></i>
                  </div>
                </td>
              </tr>
              <tr v-else>
                <td v-if="loading_pagination === false" colspan="12" class="align-middle text-center">Nenhum registro encontrado.</td>
                <td v-else colspan="12" class="align-middle text-center"><Loader /></td>
              </tr>
            </tbody>
          </table>

          <div v-if="this.allPushBatches && this.filterClient" class="pagination"> <!-- v-if para apenas quando o list_courses for carregado -->
            <button v-if="current_page>1" class="btn-pagination" @click="prevPage" :disabled="current_page === 1"><i class="bi bi-arrow-left-circle" style="
            font-size: 1.3rem;" ></i></button>
            <span>&nbsp; {{ current_page }} de {{ total_pages }} &nbsp;</span>
            <button v-if="current_page < total_pages" class="btn-pagination" @click="nextPage" :disabled="current_page === total_pages"><i class="bi bi-arrow-right-circle" style="
            font-size: 1.3rem;"></i></button>
          </div>

          <div v-if="!this.filterClient" class="container-loading">
            <i class="bi bi-exclamation-triangle-fill">
              SELECIONE UM CLIENTE
            </i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../http";
import Loader from "../shared/painel/Loader.vue";

export default {
  components: {
    Loader,
  },

  data() {
    return {
      loading: true,
      loading_pagination: false,
      allPushBatches: [],
      clients: [],
      projects: [],
      filterClient: null,
      filterProject: null,
      current_page: 1,
      search_filter: '',
    };
  },


  mounted() {
    this.getClients();
  },

  
  methods: {
    formatDate(date) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      if (this.allEnrollments) {
        this.number_enroll = this.allEnrollments.length;
      } else {
        this.number_enroll = 0;
      }

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      
    },

    getAllPushBatches(page) {
      var payload = {
        client_id: this.filterClient,
        current_page: parseInt(page),
        search_filter: this.search_filter.toString(),
      };

      http.post("painel/atend_comercial/report_push_batchs_painel/", payload)
      .then((response) => {
        this.allPushBatches = response.data.results;
        this.total_pages = response.data.total_pages;
        
        if (this.filterProject) {
          this.allPushBatches = this.allPushBatches.filter((batch) => {
            return batch.project_name === this.filterProject;
          });
        }
      })
      .finally(() => {
        this.projects = this.listProjects();
        this.loading_pagination = false;
      });
    },

    searchPushBatchByName() {
        if (this.search_filter == '') {
            this.search_filter = '';
        } else {
            this.search_filter = this.search_filter;
        }

        this.loading_pagination = true;

        this.getAllPushBatches(this.current_page);
    },

    nextPage() {
        if (this.current_page < this.total_pages) {
            this.current_page++;
            this.loading_pagination = true;
            this.getAllPushBatches(this.current_page);
        }
    },

    prevPage() {
        if (this.current_page > 1) {
            this.current_page--;
            this.loading_pagination = true;
            this.getAllPushBatches(this.current_page);
        }
    },

    onChangeFilterClient() {
      this.allPushBatches = null;
      this.projects = [];
      this.filterProject = null;

      this.filterClient = document.getElementById("selectFilterClient").value;

      if (this.filterClient) {
        this.loading_pagination = true;
        this.getAllPushBatches(this.current_page);
      }
    },

    onChangeFilterProject() {
      this.allPushBatches = null;

      this.filterClient = document.getElementById("selectFilterClient").value;
      this.filterProject = document.getElementById("selectFilterProject").value;

      if (this.filterClient) {
        this.loading_pagination = true;
        this.getAllPushBatches(this.current_page);
      }
    },

    getClients: function () {
      http.post("read_select_client/")
      .then((res) => (this.clients = res.data))
      .finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 1000);
      });
    },

    downloadFile: function (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },

    goToPage: function (id) {
      window.open("/#/push-batch/" + id, "_blank");
    },

    listProjects: function () {
      if (this.allPushBatches) {
        const uniqueProjects = this.allPushBatches
          .map((project) => project.project_name)
          .filter((project, index, array) => array.indexOf(project) === index);

        return uniqueProjects;
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-header {
  margin: 0 10px;
}
.button-action {
  cursor: pointer;
}
.link-action {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
.list-clients {
  margin-top: 10px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.btn-pagination {
  margin: none  ;
  border: none;
  background-color: transparent;
  color: black;
  font-weight: bold;
}
</style>
